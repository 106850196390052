/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'seamless-immutable';
import get from 'lodash/get';
import {
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Icon,
} from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import CircularProgress from '@material-ui/core/CircularProgress';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AlarmIcon from '@material-ui/icons/Alarm';
import add from 'date-fns/add';

import Button from '../../core/components/Button';
import DialogView from '../../core/components/DialogView';
import MapComponent from '../../locationsPage/subComponents/MapComponent';
import {
  getCurrentOrder,
  getDialogLoading,
  getCurrentUserAddresses,
  getProducts,
} from '../../../selectors';
import '../../../css/checkout/subComponents/OrderLocationDialog.scss';
import {
  DELIVERY,
  DINE_IN,
  CATERING,
  PICKUP,
  WEEKDAYARRAY,
} from '../../../services/constants/Constants';
import {
  getOutOfStockItems,
  getDayWorkingHours,
  getTime,
  isMobileMode,
  getFirstTableNumber,
  availableDeliveryOptions,
  locationIsOpen24HoursForDeliveryOption,
} from '../../../services/functions/Functions';
import { getDayMapForDeliveryOption } from '../../../services/helpers/HoursUtils';
import { FeatureFlags } from '../../../services/functions/FeatureFlag';
import { DIALOG_NAMES } from '../../../services/api/CloudEvents/constants';

const deliveryOptionText = {
  DELIVERY: 'CheckoutDrawer.orderLocationDialog.delivery',
  PICKUP: 'CheckoutDrawer.orderLocationDialog.pickup',
  DINE_IN: 'CheckoutDrawer.orderLocationDialog.dine_in',
  CATERING: 'CheckoutDrawer.orderLocationDialog.catering',
};

// TO-DO: Remove inline styles when refactoring MapComponent
const mapContainerStyle = {
  height: '35vh',
  position: 'relative',
};

const mapStyle = {
  width: 'auto',
  height: '100%',
  margin: 'auto',
  borderColor: '#000000',
  borderWidth: '2px',
  borderRadius: '1px',
  borderStyle: 'solid',
};

const OrderLocationDialog = (props) => {
  const {
    selectedLocationId,
    deliveryOption,
    actions,
    user,
    currentOrder,
    updateOrderLocation,
    closeAfterChange,
    translation,
    history,
    products,
    width,
    loading,
    dialogOpen,
    userAddresses,
    storeLocations,
    dialogLoading,
    allowDialogClose,
  } = props;

  const firstStoreLocationId = get(props, 'storeLocations[0].id');
  const [stateLocationId, setStateLocationId] = useState(selectedLocationId || firstStoreLocationId);
  const [invalidLocationId, setInvalidLocationId] = useState(null);

  useEffect(() => {
    // If there is a delay in sorting locations and the selectedLocationId is not previously set
    if (!selectedLocationId) {
      setStateLocationId(firstStoreLocationId);
    }
  }, [selectedLocationId]);

  const getSelectedLocation = (locations) => {
    if (!locations) return null;
    return locations.find(location => location.id === stateLocationId);
  };

  const handleClose = () => props.onCloseDialog();

  // Replace currentOrder.deliveryOption with 'PICKUP' or 'DINE_IN'
  // Replace currentOrder.location with selectedLocation
  const updateOrderToPickup = (selectedLocation, order, orderDeliveryOption) => {
    const pickupOrder = Immutable.asMutable(order, { deep: true });
    pickupOrder.deliveryOption = orderDeliveryOption;
    if (!selectedLocation) return pickupOrder;
    pickupOrder.location = Immutable.asMutable(selectedLocation, { deep: true });
    if (pickupOrder.location) {
      delete pickupOrder.location['delivery-option-messages'];
    }
    if (pickupOrder.deliveryOption === DINE_IN && selectedLocation.tableNumbers) {
      // Make sure our order has a table number set.
      // If there's no table number, set the first one.
      if (!pickupOrder.tableNumber) pickupOrder.tableNumber = getFirstTableNumber(selectedLocation);
      // If it already has one, check if the current table number is valid for the location.
      const selectedLocationTables = selectedLocation.tableNumbers.split(',');
      if (!selectedLocationTables.includes(pickupOrder.tableNumber)) pickupOrder.tableNumber = getFirstTableNumber(selectedLocation);
    }
    return pickupOrder;
  };

  // Replace currentOrder.deliveryOption with 'DELIVERY' or 'CATERING'
  // Add obj selectedLocation to currentOrder
  const updateOrderToDelivery = (selectedLocation, order, selectedDeliveryOption) => {
    const deliveryOrder = Immutable.asMutable(order, { deep: true });
    deliveryOrder.deliveryOption = selectedDeliveryOption;
    deliveryOrder.address = selectedLocation;
    return deliveryOrder;
  };

  const getUpdatedOrder = (selectedDeliveryOption, selectedLocation, order) => {
    if ([PICKUP, DINE_IN].includes(selectedDeliveryOption)) {
      return updateOrderToPickup(selectedLocation, order, selectedDeliveryOption);
    }
    // Handle delivery/catering locations
    return updateOrderToDelivery(selectedLocation, order, selectedDeliveryOption);
  };

  const setOrderLocation = async (locations) => {
    const selectedLocation = getSelectedLocation(locations);
    if (!selectedLocation) return;
    const updatedOrder = getUpdatedOrder(deliveryOption, selectedLocation, currentOrder);
    updateOrderLocation(selectedLocation.id);
    try {
      const response = await actions.updateOrder(closeAfterChange ? user : null, updatedOrder, currentOrder.id);

      if (response && !response.error) {
        handleClose();
      } else {
        const dialogObj = {
          dialog: 'locationSelect',
          openOrderLocationDialog: true,
        };
        if (closeAfterChange) props.onCloseDialog(dialogObj);
      }
    } catch (error) {
      console.log('API error for setOrderLocation', error);
    }
  };

  const getOneDayHours = (location, selectedDeliveryOption) => {
    if (Array.isArray(location.hours) && location.hours.length === 0) return translation('HoursComponent.locationUnavailableHours');

    const today = new Date();
    const groupedHoursForDeliveryOption = getDayMapForDeliveryOption(selectedDeliveryOption, location);
    const todayWorkingHours = getDayWorkingHours(today, location.hours);
    const todayWeekday = WEEKDAYARRAY[today.getDay()];
    const abbreviatedDay = todayWeekday.slice(0, 3);

    if (!todayWorkingHours[0]) return '';
    // index correction for javascript getDay() starting on Sunday instead of Monday
    const dayIndexForMondayStart = [6, 0, 1, 2, 3, 4, 5][today.getDay()];
    const todayHoursObj = groupedHoursForDeliveryOption[dayIndexForMondayStart];
    const todayHoursStr = todayHoursObj.hours.join(', ');
    return `${abbreviatedDay} ${todayHoursStr}`;
  };

  const handleChange = (location) => {
    // IN HERE
    // if location.id is having out of stock items, set state to render error message
    // else set the selectedLocationId
    const outOfStockItems = getOutOfStockItems(location.id, currentOrder, products);
    if (outOfStockItems.length > 0) {
      setInvalidLocationId(location.id);
    } else {
      setStateLocationId(location.id);
    }
  };

  const handleClickAdd = () => {
    const dialogObj = {
      dialog: 'newAddress',
      openDeliveryAddressDialog: true,
    };
    if (props.onChangeDialog) {
      props.onChangeDialog();
    } else {
      props.onCloseDialog(dialogObj);
    }
  };

  const generateKey = index => (`key${index}`);

  const renderLocationMap = location => (
    <MapComponent
      key={location.id}
      location={location}
      translation={translation}
      history={history}
      containerStyle={mapContainerStyle}
      mapStyle={mapStyle}
    />
  );

  const renderAddButton = selectedDeliveryOption => (
    (selectedDeliveryOption === 'DELIVERY' || selectedDeliveryOption === 'CATERING')
        && (
          <div className="orderLocationDialog-addNewAddressContainer">
            <div
              className="orderLocationDialog-addButton"
              onClick={handleClickAdd}
            >
              <Icon>control_point</Icon>
              <Typography className="orderLocationDialog-addButtonText">
                {translation('CheckoutDrawer.orderLocationDialog.addNew')}
              </Typography>
            </div>
          </div>
        )
  );

  const handleRemoveItems = async (outOfStockItems) => {
    outOfStockItems.forEach(async (item) => {
      try {
        await actions.deleteOrderItem(user, item.id, currentOrder.id, item.productItem.id);
      } catch (error) {
        console.log('API delete order item error', error);
      }
    });
    setInvalidLocationId(null);
  };

  const renderOutOfStockActionButtons = outOfStockItems => (
    <div className="orderLocationDialog-actionButtonContainer">
      <Button
        type="secondary"
        onClick={() => {
            handleClose();
          }}
        text={translation('CANCEL')}
      />
      <Button
        type="primary"
        onClick={() => {
            handleRemoveItems(outOfStockItems);
          }}
        text={translation('REMOVE')}
      />
    </div>
  );

  const renderText = (text, style) => (
    <Typography className={style}>
      {text}
    </Typography>
  );

  const renderAddressWithLocationIconForMobile = address => (
    <div className="orderLocationDialog-locationAddressSection">
      <div className="orderLocationDialog-pinContainer">
        <LocationOnIcon className="orderLocationDialog-locationPinIcon" />
      </div>
      <div>
        {address}
      </div>
    </div>
  );

  const showOneDayHours = (location, selectedDeliveryOption) => {
    const { hours } = location;
    // Delivery and Catering right now cannot show the location hours from the addresses dialog here.
    // For them, the location info currently comes after you select the address, because the API
    // needs to check what location's delivery zone the address falls within.
    if (!Array.isArray(hours)) return null;
    let oneDayHours = '';

    const today = new Date();
    oneDayHours = getOneDayHours(location, selectedDeliveryOption);

    return (
      <div className="orderLocationDialog-locationAddressSection">
        {
          oneDayHours
          && (
            <div>
              <AlarmIcon className="orderLocationDialog-clockIcon" />
            </div>
          )
        }
        <div>
          <Typography>
            {oneDayHours}
          </Typography>
        </div>
      </div>
    );
  };

  const renderPickupAddress = (location, selectedDeliveryOption) => {
    const { phone } = location;

    const addressSplit = location.address
      ? location.address.split(', ')
      : [];
    const street = addressSplit[0] || '';
    const city = addressSplit[1] || '';
    const province = addressSplit[2] || '';
    const postalCode = addressSplit[3] ? `| ${addressSplit[3]}` : '';

    const address = (
      <Fragment>
        {renderText(street)}
        {renderText(`${city}, ${province} ${postalCode}`)}
      </Fragment>
    );

    return (
      <div
        className="orderLocationDialog-locationAddressSubContainer"
        onClick={() => handleChange(location)}
      >
        {
          isMobileMode(width)
            ? (
              <Fragment>
                {renderAddressWithLocationIconForMobile(address)}
                <div className="orderLocationDialog-locationAddressSection">
                  {phone && <PhoneIcon className="orderLocationDialog-phoneIcon" />}
                  {renderText(phone, 'orderLocationDialog-paddedText')}
                </div>
                {showOneDayHours(location, selectedDeliveryOption)}
              </Fragment>
            )
            : (
              <Fragment>
                {address}
                {renderText(phone, 'orderLocationDialog-paddedText')}
                {showOneDayHours(location, selectedDeliveryOption)}
              </Fragment>
            )
        }
      </div>
    );
  };

  const renderDeliveryAddress = (location) => {
    const {
      nickname, unitNumber, streetAddress, city, postalCode,
    } = location;
    const unitNumberString = unitNumber ? `${unitNumber} - ` : '';
    const address = (
      <Fragment>
        {renderText(`${unitNumberString}${streetAddress}`)}
        {renderText(`${city} | ${postalCode}`)}
      </Fragment>
    );

    return (
      <div
        className="orderLocationDialog-locationAddressSubContainer"
        onClick={() => handleChange(location)}
      >
        {
          isMobileMode(width)
            ? (
              <Fragment>
                {renderAddressWithLocationIconForMobile(address)}
              </Fragment>
            )
            : address
        }
      </div>
    );
  };

  const renderAddress = (location, selectedDeliveryOption) => {
    if ([PICKUP, DINE_IN].includes(selectedDeliveryOption)) return renderPickupAddress(location, selectedDeliveryOption);
    return renderDeliveryAddress(location, selectedDeliveryOption);
  };

  const renderLocationDetails = (location, selectedDeliveryOption) => {
    // Store Location: name
    // User Address: nickname
    const name = location.name || location.nickname;

    const containerStyle = stateLocationId === location.id
      ? 'orderLocationDialog-locationAddressSelectedContainer'
      : 'orderLocationDialog-locationAddressUnselectedContainer';

    return (
      <div
        key={`${name} ${location.id}`}
        className={containerStyle}
      >
        <FormControl className="orderLocationDialog-formControl">
          <RadioGroup
            aria-label={name}
            name={name}
            value={`${stateLocationId}`}
            onChange={() => handleChange(location)}
            className="orderLocationDialog-radioGroup"
          >
            <FormControlLabel
              value={`${location.id}`}
              control={
                <Radio className="orderLocationDialog-formControlLabel" />
              }
              label={renderText(name, 'orderLocationDialog-largerBoldText')}
              className="orderLocationDialog-noMarginLeft"
            />
            {renderAddress(location, selectedDeliveryOption)}
          </RadioGroup>
        </FormControl>
      </div>
    );
  };

  const dialogContent = (selectedDeliveryOption, locations) => {
    const currentLocation = getSelectedLocation(locations);
    const outOfStockItems = getOutOfStockItems(invalidLocationId, currentOrder, products);

    const hideMapComponentOnMobile = FeatureFlags.CheckoutDrawer.OrderLocationDialog.hideMapComponentOnMobile
      && isMobileMode(width);

    return (
      invalidLocationId
        ? (
          <Fragment>
            <div className="orderLocationDialog-outOfStockMessageContainer">
              <Typography className="orderLocationDialog-outOfStockDialogMessage">
                {translation('CheckoutDrawer.orderLocationDialog.outOfStockMessage')}
              </Typography>
            </div>
            <div className="orderLocationDialog-outOfStockItems">
              {
                loading !== 0
                ? <CircularProgress />
                : (
                    outOfStockItems.map((item, index) => (
                      <Typography
                        className="orderLocationDialog-outOfStockItem"
                        key={generateKey(index)}
                      >
                        {item.productItem.name}
                      </Typography>
                    ))
                  )
              }
            </div>
            {
              loading === 0
              && (
                <div className="orderLocationDialog-outOfStockActionButtons">
                  {renderOutOfStockActionButtons(outOfStockItems)}
                </div>
              )
            }
          </Fragment>
        )
        : (
          <Fragment>
            <div>
              {
                !hideMapComponentOnMobile && locations && currentLocation
                && renderLocationMap(currentLocation)
              }
            </div>
            <div className="orderLocationDialog-locationsContainer">
              {
                locations
                && locations.map(location => (
                  renderLocationDetails(location, selectedDeliveryOption)
                ))
              }
              {
                renderAddButton(selectedDeliveryOption)
              }
            </div>
          </Fragment>
        )
    );
  };

  const navigateBack = () => props.navigateBack();

  const renderActionButtons = (locations) => {
    if (invalidLocationId) return <div />;
    const multipleDeliveryOptions = availableDeliveryOptions().length > 1;

    return (
      <div className="orderLocationDialog-actionButtonContainer">
        {
          multipleDeliveryOptions
          && (
            <Button
              variant="text"
              overrideClass
              type="primary"
              className="orderLocationDialog-backButtonStyle"
              onClick={navigateBack}
              startIcon={<ArrowBackIcon className="orderLocationDialog-backArrowIcon" />}
              text={translation('BACK')}
            />
          )
        }
        <Button
          id="confirmLocation"
          className="confirmButton"
          overrideClass
          disabled={stateLocationId === 0}
          type="primary"
          onClick={() => setOrderLocation(locations)}
          text={translation('CheckoutDrawer.orderLocationDialog.confirm')}
        />
      </div>
    );
  };

  const locations = ([PICKUP, DINE_IN].includes(deliveryOption))
    ? storeLocations
    : userAddresses;
  const dialogTitleText = invalidLocationId
    ? translation('CheckoutDrawer.orderLocationDialog.productsOutOfStock')
    : translation(deliveryOptionText[deliveryOption]);

  const dialogTitleStyle = allowDialogClose
    ? 'orderLocationDialog-dialogTitleStyle'
    : 'orderLocationDialog-dialogTitleWithPadding';

  return (
    <DialogView
      dialogName={DIALOG_NAMES.ORDER_LOCATION}
      open={dialogOpen}
      titleAlignClose
      titleHasCloseBtn={allowDialogClose}
      handleClose={() => handleClose()}
      dialogCloseIconColor="orderLocationDialog-dialogCloseIconColor"
      disableBackdropClick={!allowDialogClose}
      disableEscapeKeyDown={!allowDialogClose}
      // TO-DO: Remove inline styles when refactoring DialogView
      dialogPaperStyle={{ width: '875px' }}
      dialogBodyContainerStyle="orderLocationDialog-dialogBodyContainerStyle"
      dialogContentStyle="orderLocationDialog-dialogContentStyle"
      dialogTitleText={dialogTitleText}
      dialogTitleStyle={dialogTitleStyle}
      dialogTitleStyleVariant="h2"
      hasDialogContent
      renderDialogContent={() => dialogContent(deliveryOption, locations)}
      hasDialogContent2={false}
      hasDialogActions
      renderActionBtn={() => renderActionButtons(locations)}
      loading={!!dialogLoading}
    />
  );
};

OrderLocationDialog.propTypes = {
  updateOrderLocation: PropTypes.func.isRequired,
  width: PropTypes.string,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  deliveryOption: PropTypes.string.isRequired,
  translation: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  currentOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  dialogLoading: PropTypes.number.isRequired,
  loading: PropTypes.number.isRequired,
  navigateBack: PropTypes.func.isRequired,
  selectedLocationId: PropTypes.number,
  userAddresses: PropTypes.arrayOf(PropTypes.any),
  storeLocations: PropTypes.arrayOf(PropTypes.any),
  closeAfterChange: PropTypes.bool,
  onChangeDialog: PropTypes.func,
  user: PropTypes.objectOf(PropTypes.any),
  products: PropTypes.arrayOf(PropTypes.object),
  allowDialogClose: PropTypes.bool,
};

OrderLocationDialog.defaultProps = {
  user: null,
  userAddresses: null,
  storeLocations: null,
  closeAfterChange: true,
  onChangeDialog: null,
  selectedLocationId: null,
  products: null,
  allowDialogClose: true,
  width: null,
};

const mapStateToProps = state => ({
  currentOrder: getCurrentOrder(state),
  dialogLoading: getDialogLoading(state),
  userAddresses: getCurrentUserAddresses(state),
  products: getProducts(state),
});

export default connect(mapStateToProps)(withWidth()(OrderLocationDialog));
